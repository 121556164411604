import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import Numbers from "../../components/numbers";
import IndividualPlan from "../../components/individualPlan";

import { Link } from "gatsby";

const AboutFirm = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Über uns"
      lang={lang}
      translationPL="/o-firmie/"
      translationEN="/en/about-us"
    >
      <SmallBanner title="Über uns" lang={lang} />
      <section className="firm-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">
              Informationen <br />zur Firma
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100 firm-info__left-padding">
              Das Unternehmen PalettenWerk ist seit 1990 auf dem Markt tätig und aktuell gehört er zu den größten Herstellern und Exporteuren von Holzpaletten in Polen. Während seiner fast 30-jährigen Tätigkeit konnte das Unternehmen reiche Erfahrungen in der Produktion und Lieferung von Paletten für fast alle Industriezweige und Branchen sammeln. Die Paletten von PalettenWerk werden von mehreren hundert Unternehmen aus der Lebensmittel-, Bau-, Chemie-, Pharma-, Brauerei-, Metall- und anderen Branchen eingesetzt.
              </p>
              <img
                className="firm-info__left-padding"
                src={require("../../assets/img/firm-info1.jpg")}
                alt="Firm info"
              />
            </div>
          </div>
          <div className="row firm-info__center-row">
            <div className="col-12 col-lg-6 col-xl-4">
              <div
                className="center-row__image"
                style={{
                  backgroundImage: `url(${require("../../assets/img/firm-info2.jpg")})`,
                }}
              ></div>
            </div>
            <div className="col-12 col-lg-6 col-xl-8">
              <p className="firm-info__left-padding">
              Jeden Monat liefern wir 900 Lastwagen Paletten an Kunden in Polen und im Ausland. Dank seinen Qualitäts- und Kundendienststandards kann PalettenWerk die Erwartungen selbst der anspruchsvollsten in- und ausländischen Kunden erfüllen. Zu den Kunden von PalettenWerk gehören die größten Konzerne und Gesellschaften. 60% der Produktion wird in über 15 europäische Länder exportiert.
              </p>
            </div>
          </div>
          <div className="row firm-info__last-row">
            <div className="col-12 col-lg-6 col-xl-8">
              <p>
              Um die Qualität zu gewährleisten und die Produktion und Logistik zu optimieren, führt PalettenWerk eine Reihe von Maßnahmen durch. Dazu gehören das Qualitätsmanagementsystem, das Organisationssystem für Bestellungen und Lieferungen, das Rohstoffmanagementsystem, das System zur Unterstützung des Materialflusses und das tägliche Programm zur Überwachung von Verlusten und Ausfällen.
              </p>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
              <img
                className="last-row__image"
                src={require("../../assets/img/firm-info3.jpg")}
                alt="Firm info"
              />
            </div>
          </div>
        </div>
      </section>
      <Numbers lang={lang} />
      <section className="timeline" id="30-jahre-auf-dem-markt">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-20">30 Jahre auf dem Markt:</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100">
                Unsere Geschichte begann im Jahr 1990. In 30 Jahren hat sich unser Unternehmen von einem kleinen Handelsunternehmen zu einem der größten Palettenhersteller Europas mit mehr als 7 Millionen Paletten pro Jahr vermarktet. Wir haben das durch kontinuierliche Modernisierung und Investitionen zur Optimierung von Produktion, Management und Vertrieb unserer Produkte erreicht. Trotz dieses hohen Niveaus behält das Unternehmen immer noch den Charakter eines Familienunternehmens, das Tradition mit modernen betriebswirtschaftlichen Techniken verbindet. Dadurch sind wir in der Lage, freundliche Arbeitsbedingungen zu schaffen, alle Mitarbeiter fühlen sich wohl und oft sind das Personen, die schon seit den Anfängen unseres Unternehmens mit uns zusammenarbeiten. Die vergangenen 30 Jahre sind ein guter Ausgangspunkt für die weitere Entwicklung und für die Bewältigung von Herausforderungen der sich dynamisch verändernden Wirtschaft.
              </p>
            </div>
          </div>
        </div>
        <div className="timeline__container">
          <div className="timeline__holder">
            <div className="timeline__item">
              <p className="item__date xxl color--main">1990</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline1.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                Unsere Geschichte begann im Jahr 1990, als wir die ersten Palettenlieferungen auf den deutschen Markt abgewickelt haben. Dabei handelte es sich zu einem großen Teil um Paletten, die von kleinen Unternehmen gekauft wurden.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1992</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline2.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
                1992 starteten wir mit unserer eigenen Produktion am ersten Standort unseres Unternehmens - Bystra Podhalańska. Auf einer gebrauchten, im Ausland gekauften, automatischen Linie haben wir nur EURO-Paletten hergestellt..
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1995</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline3.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
               Im Jahr 1995 haben wir als einer der ersten Betriebe in Polen eine spezielle Linie für die Herstellung von untypischen Paletten eingerichtet, dank der wir die Kundenbedürfnisse umfassend befriedigen und die Rohstoffe optimal nutzen konnten.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">1998</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline4.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
               1998 haben wir das Gelände in Gniewięcin, Woiwodschaft Heiligkreuz, erworben, wo die Firma Palko sp. z o.o. zur Herstellung von EPAL Paletten gegründet wurde.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2000</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline5.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
              Im Jahr 2000 haben wir ein Grundstück in Jordanów gekauft und unsere Firma an einen neuen Standort verlegt. Auf dem neuen, viel größeren Gelände wurden eine moderne Halle mit einer Fläche von über 3000m2, ein Trocknungskomplex und eine neue EPAL-Palettenproduktionslinie aufgebaut.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2000-2020</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline6.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
              In den Jahren 2000-2020 haben wir intensiv in Maschinen und überdachte Lagerräume sowie in moderne Trockenräume und Öfen investiert. Wir haben unter anderem drei automatische Linien für die Herstellung von Erba OMEV-Paletten, zwei CAPE-Produktionslinien und zwei automatische Linien für die Herstellung von DHP-Paletten erworben
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2018</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline7.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
              2018 haben wir die Produktion in unserem neuen Werk in der Ukraine, in Tscherwonohrad, aufgenommen, von wo aus wir die Paletten sowohl in die Ukraine als auch in die Nachbarländer liefern.
              </p>
            </div>
            <div className="timeline__item">
              <p className="item__date xxl color--main">2020</p>
              <div
                className="item__image mb-20"
                style={{
                  backgroundImage: `url(${require("../../assets/img/timeline8.jpg")})`,
                }}
              ></div>
              <p className="mb-40">
              Derzeit verfügt die PalettenWerk-Gruppe über 30 Hektar überdachte Lagerfläche, 26 moderne Trockner mit Wärmerückgewinnung und 9 vollautomatische Produktionslinien.
              </p>
            </div>
            <div className="timeline__end-element"></div>
          </div>
        </div>
      </section>
      <IndividualPlan
        title="Warum wir?"
        text="Das gigantische Produktions- und Lagerpotential und der volle logistische Service haben uns zum Marktführer in der Palettenproduktionsindustrie in Polen und Europa gemacht. Wir sind bereit, die Erwartungen der anspruchsvollsten Kunden in jeder Hinsicht zu erfüllen. Und das alles dank unserer langjährigen Erfahrung, unserem Team qualifizierter Mitarbeiter, technischen und logistischen Einrichtungen und dem ständigen Wunsch, unsere Arbeit stets zu verbessern. Als Bestätigung der hohen Qualität unserer Produkte gelten zahlreiche Zertifikate, die wir besitzen, u.a. EPAL Lizenz (für alle Arten von Paletten), CP Lizenz (Chemiepaletten, alle Arten), ISPM 15 Zertifikat, ISO 9001:2015 Qualitätszertifikat, PEFC Zertifikat, AEO-Zulassung und andere. Wir richten uns nach dem Prinzip, dass der größte Wert unser Kunde ist."
        lang={lang}
      />
      <section className="firm-info-ecology">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main">Umweltschutz:</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-100">
                Das Unternehmen PalettenWerk setzt sich für eine vernünftige und sichere Einwirkung auf die Umwelt ein. Die Umweltpolitik von PalattenWerk umfasst einen effizienten und sparsamen Einsatz von Rohstoffen und Energie sowie die Minimierung der negativen Einwirkungen von Produktionsprozessen auf die Umwelt.
              </p>
            </div>
            <div className="col-12 offer-quality__btn-holder">
              <Link
                className="btn"
                to="/ochrona-srodowiska/"
                data-hover="Mehr sehen"
              >
                Mehr sehen
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutFirm;
